// import external dependencies
import 'jquery';
import Splide from '@splidejs/splide';

// Import everything from autoload


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// import then needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// import the Facebook and Twitter icons
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faAngleRight, faAngleUp, faMagnifyingGlass, faList, faTimes, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot, faMobile, faEnvelope, faPhone, faUser, faCartShopping } from '@fortawesome/pro-light-svg-icons';

// add the imported icons to the library
library.add(faFacebookF, faTwitter, faInstagram, faLocationDot, faUser, faList, faTimes, faSquarePlus, faLinkedin, faCartShopping, faMagnifyingGlass, faMobile, faPhone, faEnvelope, faAngleRight, faAngleUp);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

// Load Events
jQuery(document).ready(() => routes.loadEvents());



document.addEventListener('DOMContentLoaded', function () {
  var $dropdowns = getAll('.navbar-item.has-dropdown:not(.is-hoverable)');

  if ($dropdowns.length > 0) {
    $dropdowns.forEach(function ($el) {
      $el.addEventListener('click', function (e) {
        e.stopPropagation();
        $el.classList.toggle('is-active');
      });
    });

    document.addEventListener('click', function (event) {
      event.closeDropdowns();
    });
  }

  function closeDropdowns() {
    $dropdowns.forEach(function ($el) {
      $el.classList.remove('is-active');
    });
  }

  // Close dropdowns if ESC pressed
  document.addEventListener('keydown', function (event) {
    var e = event || window.event;
    if (e.keyCode === 27) {
      closeDropdowns();
    }
  });

  // Toggles

  var $burgers = getAll('.burger');

  if ($burgers.length > 0) {
    $burgers.forEach(function ($el) {
      $el.addEventListener('click', function () {
        var target = $el.dataset.target;
        var $target = document.getElementById(target);
        $el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
      });
    });
  }

  // Functions

  function getAll(selector) {
    return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
  }
});

//Slider
var slider = document.getElementsByClassName('splide');

if (slider.length > 0) {
  var splide = new Splide('.splide',
    {
      type: 'loop',
      pagination: false,
      height: '925px',
      autoplay: true,
      breakpoints: {
        640: {
          height: '400px',
          perPage: 1,
          arrows: false,
        },
      },
    }
  );
  splide.mount();
}

// splide.on('visible', function (slide) {

//   const titles = document.querySelectorAll('.splide__slide .splide__slide_title_h1');
//   const subtitles = document.querySelectorAll('.splide__slide .splide__slide_title_p');
//   const buttons = document.querySelectorAll('.splide__slide .wp-block-button');

//   const title = document.querySelector('.slide-' + slide.index + ' .splide__slide_title_h1');
//   const subtitle = document.querySelector('.slide-' + slide.index + ' .splide__slide_title_p');
//   const button = document.querySelector('.slide-' + slide.index + ' .wp-block-button');


//   titles.forEach((t) => {
//     t.classList.remove('animate__animated', 'animate__fadeInDownBig', 'animate__slower');
//   });

//   subtitles.forEach((s) => {
//     s.classList.remove('animate__animated', 'animate__fadeInDownBig', 'animate__slow');
//   });

//   buttons.forEach((b) => {
//     b.classList.remove('animate__animated', 'animate__fadeInUpBig', 'animate__slow');
//   });


//   title.classList.add('animate__animated', 'animate__fadeInDownBig', 'animate__slower');
//   subtitle.classList.add('animate__animated', 'animate__fadeInDownBig', 'animate__slow');
//   button.classList.add('animate__animated', 'animate__fadeInUpBig', 'animate__slow');

// });

$('.wc-block-grid__product').mouseover(function () {
  $(this).children('.wc-block-grid__product-add-to-cart').toggleClass('show-addtocart');
});

$('.wc-block-grid__product').mouseout(function () {
  $(this).children('.wc-block-grid__product-add-to-cart').toggleClass('show-addtocart');
});



let scrollpos = window.scrollY
const header = document.querySelector('.banner')
const header_height = 60;

const add_class_on_scroll = () => header.classList.add('sticky-header')
const remove_class_on_scroll = () => header.classList.remove('sticky-header')

window.addEventListener('scroll', function () {
  scrollpos = window.scrollY;

  if (scrollpos >= header_height) { add_class_on_scroll() }
  else { remove_class_on_scroll() }
})


document.addEventListener('DOMContentLoaded', () => {
  const modalTrigger = document.querySelector('[data-target="follow-us-modal"]');
  const modal = document.getElementById('follow-us-modal');
  const modalClose = modal.querySelector('.delete');

  modalTrigger.addEventListener('click', () => {
    modal.classList.add('is-active');
  });

  modalClose.addEventListener('click', () => {
    modal.classList.remove('is-active');
  });
});


